.App {
  text-align: center;
  background: #E5E5E5;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 2px #bdbdbd;
    position: fixed;
    width: 100%;
    top: 0;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #4F4F4F;
    color: white;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    
}
.footer a {
  padding: 5px 30px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF;
  text-decoration: none;
}

.App-link {
  color: #61dafb;
}

.container1 {
    display: flex;
    justify-content: center;
    padding-top: 80px;
}

.container2 {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.container1 img, .container2 img {
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
}

.line {
    font-family: 'Open Sans';
    font-style: normal;
    letter-spacing: 0em;
    font-weight: 400; 
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #121010;
    padding-top: 40px;
}

.line a {
  text-decoration: underline;
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
